<template>
  	<a-back-top>
        <div class="ant-back-top-inner">
          <ArrowUpOutlined />
        </div>
    </a-back-top>
</template>

<script>
  import { ArrowUpOutlined } from '@ant-design/icons-vue'

  export default {
  	name:'backtop',
  	components:{ArrowUpOutlined},
  	setup(){
      
  	
  		return {
  			
  		}
  	}
  }
</script>

<style type="text/css" scoped>
.ant-back-top{bottom:30px; right:50px; z-index:999;}
.ant-back-top-inner{font-size:30px; color:#999; width:52px; height: 52px; line-height: 52px; text-align:center; box-shadow: 1px 1px 4px #eee; border-radius: 5px; background: #f9f9f9;}
.ant-back-top-inner:hover{color:#3e9cef; background:#eee;}

@media screen and (max-width:800px) {
  .ant-back-top{bottom:12px; right:12px; }
  .ant-back-top-inner{font-size:25px; color:#999; width:42px; height: 42px; line-height: 42px; }
  .ant-back-top-inner:active{color:#3e9cef; background:#eee;}

}

</style>