<template>
	<div class="d-title" :style="{color:color}">
		<div class="wrap" >
			<span class="before" :style="{background:color}" v-if="!hideLine"></span>
			<p>{{text}}</p>
			<span class="after" :style="{background:color}" v-if="!hideLine"></span>
		</div>
	</div>
</template>

<script>

export default {
	name: 'DHeader',
	props:{
		text:String,
		color:String,
		fontsize:String,
		hideLine:{type:Boolean,default:false}
	},
	setup(){
 		return {}
	}
}
</script>

<style scoped>
.d-title{text-align: center;}
.d-title .wrap{font-size: 36px; position: relative; display:inline-block;}
.d-title .wrap .before,.d-title .wrap .after {
    position: absolute;
    top: 50%;
    height: 2px;
    width: 80px;
    margin: 0 10px;
    display: inline-block;
    zoom: 1;
}
.d-title .wrap .before {right: 100%; background: #333;}
.d-title .wrap .after {left: 100%; background: #333;}
.d-title p{line-height: 1.4;}


@media screen and (max-width: 1000px) {
 .d-title .wrap{font-size: 22px;}
 .d-title .wrap .before,.d-title .wrap .after {
    position: absolute;
    top: 50%;
    height: 1px;
    width: 50px;
    margin: 0 10px;
    display: inline-block;
    zoom: 1;
}
}
</style>