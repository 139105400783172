import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import './styles/common.css'

// import store from './store'
import router from '@/router'

createApp(App)
// .use(store)
.use(router)
.use(Antd)
.mount('#app')



/*全局配置*/
import { message } from 'ant-design-vue';

 message.config({
    top:'100px',
    maxCount:1
});