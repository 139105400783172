<template>
	<footer>
		<div class="wrap">
			<p>广东增减材科技有限公司 Copyright © 1994 - 2019. All rights reserved.<a href="https://beian.miit.gov.cn/" rel="nofollow"> 粤ICP备2021014219号</a></p>
			<p>技术支持：佛山新媒体科技有限公司</p>
		</div>
	</footer>
</template>

<script>
import DTitle from '@/components/DTitle.vue'

export default {
	name: 'dfooter',
	components:{DTitle},
	props:{

	},
	setup(){
 		return {}
	}
}
</script>

<style scoped>
footer{background: #fff; height:120px; }
footer .wrap{max-width:1200px; margin:auto; text-align: center; padding-top:30px; font-size:14px;}
footer .wrap p{line-height: 1.6;}
@media screen and (max-width: 800px) {
	footer{background: #fff; height:90px; }
	footer .wrap{padding-top:15px; font-size:12px;}
}
</style>