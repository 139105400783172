/* eslint-disable */
import {createRouter, createWebHistory,createWebHashHistory} from 'vue-router'

const routerHistory = createWebHistory();
// const hashHistory = createWebHashHistory();

const Index = () => import('@/pages/index/index')
// const Detail = () => import('@/pages/detail/index')
// 关于我们
const About = () => import('@/pages/about/about')
const Ab1 = () => import('@/pages/about/ab1')
const Ab2 = () => import('@/pages/about/ab2')
const Ab3 = () => import('@/pages/about/ab3')
const Ab4 = () => import('@/pages/about/ab4')



// 核心装备
const Product = () => import('@/pages/product/product')
// 应用案例
const Case = () => import('@/pages/case/case')

const News = () => import('@/pages/news/news')
const Detail = () => import('@/pages/news/detail')



const router = createRouter({
	history: routerHistory,
	// history: hashHistory,
	scrollBehavior: () => ({ y: 0 }),
	routes: [
	  {
	   	path: '/',
	   	redirect: '/index'
	  }
	  ,{
	   	path:'/index',
	    name:'index',
	    component:Index
	  }
	  ,{
	   	path:'/about',
	   	name:'about',
	   	redirect: '/about/ab1',
	   	component:About,
	   	children:[
	   		{ path:'ab1', component: Ab1 },
	   		{ path:'ab2', component: Ab2 },
	   		{ path:'ab3', component: Ab3 },
	   		{ path:'ab4', component: Ab4 }
	   ]
	  },{
	   	path:'/product',
	   	name:'product',
	   	component:Product,
	  },{
	   	path:'/case',
	   	name:'case',
	   	component:Case,
	  },
	  {
	   	path:'/news',
	   	name:'news',
	   	component:News,
	  },
	  {
	   	path:'/news/:id',
	   	name:'detail',
	   	component:Detail,
	  }
	  // ,{
	  //  	path:'/news/:id',
	  //  	//name:'news',
	  //  	component:News,
	  //  	children:[{ path:'', component: Detail }],
	  // },
	  // {
	  //  	path:'/news/:id',
	  //  	name:'detail',
	  //  	component:Detail,
	  // }

	]
})

router.beforeEach((to, form, next)=>{
	document.documentElement.scrollTop = 0;
	document.body.scrollTop = 0;
	next();
})

export default router;