<template>
  <div id="">
    <router-view v-slot="{ Component }">
        <transition name="fade-transform" mode="out-in">
          <component :is="Component" />
        </transition>
    </router-view>    
    <!-- <back-top></back-top> -->
<!--     <d-footer ></d-footer> -->
  </div>
</template>

<script>
import { onMounted} from 'vue'
// import {useStore} from 'vuex'
// import {useRouter, useRoute} from 'vue-router'
import DFooter from '@/components/DFooter.vue'
import backTop  from '@/components/backTop.vue'

export default {
  name: 'App',
  components:{DFooter,backTop},
  setup(){
    // const route = useRoute();
    // const store = useStore();

    onMounted(()=>{
        document.dispatchEvent(new Event('custom-render-trigger'))
    })

    return {}
  }
}
</script>

<style scoped>
 .contents{min-height: calc(100vh - 229px); padding-bottom: 50px;}
 .contents.hide{min-height:0;padding-bottom: 0px;}
</style>
